/* eslint-disable indent */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import MaterialButton from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import { FaEllipsisH, FaTrash, FaRegEye, FaKey, FaEdit } from 'react-icons/fa'
import moment from 'moment'

import ModalOverlay from '../../components/ModalOverlay/Loadable'
import Spinner from '../../components/Spinner/Loadable'
import Button from '../../components/Button/Loadable'
import ModalNotification from '../../components/ModalNotification/Loadable'
import TextInput from '../../components/TextInput/Loadable'
import Dropdown from '../../components/Dropdown/Loadable'
import fetchErrorHandler from '../../utils/fetchErrorHandler'

import * as APIKeyPageActions from './actions'
import * as ProjectPageActions from '../ProjectPageLayout/actions'

import APIKeyPageStyled from './styledComponent'

import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FRONTEND_ROUTES } from '../../utils'

const STATUS_DEPLOY = 'DEPLOYED'
const STATUS_WORK_IN_PROGRESS = 'WORK_IN_PROGRESS'
const STATUS_INITIAL = 'INITIAL'
const STATUS_CAMERAS_ASSESSMENT = 'CAMERA_ASSESSMENT'

const StyledListItemIcon = withStyles({
  root: {
    width: '16px',
    minWidth: '16px',
    marginRight: '16px',
    fontSize: '16px'
  }
})(ListItemIcon)

const StyledTypography = withStyles({
  root: {
    fontFamily: 'Prompt, sans-serif',
    fontSize: '14px'
  }
})(Typography)

export class APIKeyPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedAPIKeyData: undefined,
      showDeleteAPIKeyModal: false,
      showAPIKeyModal: false,
      showCopyLinkModal: false,
      showCopyAPIKeyTooltip: false,
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onOverLayClose: undefined
      },
      anchorElOption: undefined,
      listDeployStatus: [
        { text: 'Initial Farm', value: STATUS_INITIAL },
        { text: 'Working-in-progress', value: STATUS_WORK_IN_PROGRESS },
        { text: 'Camera Assessments', value: STATUS_CAMERAS_ASSESSMENT },
        { text: 'Deployed', value: STATUS_DEPLOY }
      ]
    }
  }

  async componentDidMount() {
    await this.props.loadProjectList()
    await this.props.loadListAPIKey()
    this.checkUserPermissionAdmin()
  }

  componentDidUpdate(prevProps, prevState) {
    this._handleFetchLoadListAPIKey(prevProps)
    this._handleFetchCreateNewAPIKey(prevProps)
    this._handleFetchUpdateAPIKey(prevProps)
    this._handleFetchDeleteAPIKey(prevProps)
    this._handleFetchSignAPIKey(prevProps)
    this.checkUserPermissionAdmin()
  }

  checkUserPermissionAdmin() {
    if (!this.props.currentUser.admin) {
      this.props.history.replace(FRONTEND_ROUTES.homeRoute)
    }
  }

  _handleFetchUpdateAPIKey(prevProps) {
    if (
      prevProps.apiKeyPage.updateAPIKey.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.apiKeyPage.updateAPIKey.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.handleFetchUpdateAPIKeyFailure()
    }
  }

  _handleFetchLoadListAPIKey(prevProps) {
    if (
      prevProps.apiKeyPage.loadListAPIKey.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.apiKeyPage.loadListAPIKey.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.handleFetchLoadListAPIKeyFailure()
    }
  }

  _handleFetchCreateNewAPIKey(prevProps) {
    if (
      prevProps.apiKeyPage.createNewAPIKey.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.apiKeyPage.createNewAPIKey.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.handleCreateNewAPIKeySuccess()
    }
    if (
      prevProps.apiKeyPage.createNewAPIKey.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.apiKeyPage.createNewAPIKey.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.handleFetchCreateListAPIKeyFailure()
    }
  }

  _handleFetchDeleteAPIKey(prevProps) {
    if (
      prevProps.apiKeyPage.deleteAPIKey.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.apiKeyPage.deleteAPIKey.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.handleFetchDeleteListAPIKeyFailure()
    }
  }

  _handleFetchSignAPIKey(prevProps) {
    if (
      prevProps.apiKeyPage.signAPIKey.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.apiKeyPage.signAPIKey.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.handleSignAPIKeySuccess()
    }
    if (
      prevProps.apiKeyPage.signAPIKey.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.apiKeyPage.signAPIKey.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.handleFetchSignAPIKeyFailure()
    }
  }

  handleCreateNewAPIKeySuccess() {
    this.setState({
      selectedAPIKeyData: this.props.apiKeyPage.createNewAPIKey.apiKeyData
    })
    this.setShowCopyLinkModal()
  }

  handleSignAPIKeySuccess() {
    this.setState({
      selectedAPIKeyData: this.props.apiKeyPage.signAPIKey.apiKeyData
    })
    this.setShowCopyLinkModal()
  }

  handleFetchCreateListAPIKeyFailure() {
    const statusCode = this.props.apiKeyPage.createNewAPIKey.error.status
    fetchErrorHandler(
      statusCode,
      {
        401: this.forceLogin
      },
      this.showDefaultFailureModal
    )
  }

  handleFetchLoadListAPIKeyFailure() {
    const statusCode = this.props.apiKeyPage.loadListAPIKey.error.status
    fetchErrorHandler(
      statusCode,
      {
        401: this.forceLogin
      },
      this.showDefaultFailureModal
    )
  }

  handleFetchUpdateAPIKeyFailure() {
    const statusCode = this.props.apiKeyPage.updateAPIKey.error.status
    fetchErrorHandler(
      statusCode,
      {
        401: this.forceLogin
      },
      this.showDefaultFailureModal
    )
  }

  handleFetchDeleteListAPIKeyFailure() {
    const statusCode = this.props.apiKeyPage.deleteAPIKey.error.status
    fetchErrorHandler(
      statusCode,
      {
        401: this.forceLogin
      },
      this.showDefaultFailureModal
    )
  }

  handleFetchSignAPIKeyFailure() {
    const statusCode = this.props.apiKeyPage.signAPIKey.error.status
    fetchErrorHandler(
      statusCode,
      {
        401: this.forceLogin
      },
      this.showDefaultFailureModal
    )
  }

  forceLogin = () => {
    this.props.history.push(FRONTEND_ROUTES.loginRoute)
  }

  setShowCopyLinkModal = () => {
    this.setState({
      showCopyLinkModal: true
    })
  }

  showDefaultFailureModal = () => {
    this.setShowFailureModalData('Failure', 'Something wrong, Please try again.', this.setHideFailureModalData)
  }

  setShowFailureModalData = (title, message, onOverLayClose) => {
    this.setState({
      failureModalData: {
        show: true,
        title,
        message,
        onOverLayClose
      }
    })
  }

  setHideFailureModalData = () => {
    this.setState({
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onOverLayClose: undefined
      }
    })
  }

  copyToClipBoard(token) {
    const mockInput = document.createElement('input')
    mockInput.value = token
    mockInput.setAttribute('readonly', '')
    mockInput.style = { position: 'absolute', left: '-9999px' }
    document.body.appendChild(mockInput)
    mockInput.select()
    document.execCommand('copy')
    document.body.removeChild(mockInput)
  }

  showDeleteAPIKeyModal = () => {
    this.setState({
      showDeleteAPIKeyModal: true
    })
  }

  handleCopyLinkClicked = (data) => {
    this.props.signAPIKey(data.id)
  }

  handleOptionsClicked = (e, data) => {
    this.setState({
      anchorElOption: e.currentTarget,
      selectedAPIKeyData: data
    })
  }

  renderDeployStatus(deployStatus) {
    let deployStatusClassName = ''
    if (deployStatus === STATUS_WORK_IN_PROGRESS) {
      deployStatusClassName = 'work-in-progress'
    } else if (deployStatus === STATUS_CAMERAS_ASSESSMENT) {
      deployStatusClassName = 'camera-assessment'
    } else if (deployStatus === STATUS_DEPLOY) {
      deployStatusClassName = 'deploy'
    } else if (deployStatus === STATUS_INITIAL) {
      deployStatusClassName = 'initial'
    }
    const deployData = this.state.listDeployStatus.find((data) => data.value === deployStatus)
    return <span className={`deploy-status ${deployStatusClassName}`}>{deployData ? deployData.text : '-'}</span>
  }

  getTableBody() {
    return this.props.apiKeyPage.listAPIKey.map((data) => {
      const deployStatus = this.renderDeployStatus(data.status)
      return (
        <TableRow key={data.token}>
          <TableCell>{data.project_id}</TableCell>
          <TableCell align="left">
            <Tooltip title={data.project_name}>
              <div className="prevent-text-overflow">{data.project_name}</div>
            </Tooltip>
          </TableCell>
          <TableCell align="left">
            <Tooltip title={data.codedeploy_name}>
              <div className="prevent-text-overflow">{data.codedeploy_name}</div>
            </Tooltip>
          </TableCell>
          <TableCell align="left">
            <Tooltip title={data.codedeploy_tag}>
              <div className="prevent-text-overflow">{data.codedeploy_tag}</div>
            </Tooltip>
          </TableCell>
          <TableCell align="left">
            <Tooltip title={data.zerotier_id}>
              <div className="prevent-text-overflow">{data.zerotier_id}</div>
            </Tooltip>
          </TableCell>
          <TableCell align="left">{deployStatus}</TableCell>
          <TableCell align="left">
            <MaterialButton aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => this.handleOptionsClicked(e, data)}>
              <FaEllipsisH />
            </MaterialButton>
          </TableCell>
        </TableRow>
      )
    })
  }

  getLoading() {
    let output = null
    const loadListAPIKeyFetchStatus = this.props.apiKeyPage.loadListAPIKey.fetchStatus
    const createNewAPIKeyFetchStatus = this.props.apiKeyPage.createNewAPIKey.fetchStatus
    const updateAPIkeyFetchStatus = this.props.apiKeyPage.updateAPIKey.fetchStatus
    const deleteAPIKeyFetchStatus = this.props.apiKeyPage.deleteAPIKey.fetchStatus
    const signAPIKeyFetchStatus = this.props.apiKeyPage.signAPIKey.fetchStatus
    if (
      loadListAPIKeyFetchStatus === FETCH_STATUS_REQUEST ||
      createNewAPIKeyFetchStatus === FETCH_STATUS_REQUEST ||
      deleteAPIKeyFetchStatus === FETCH_STATUS_REQUEST ||
      updateAPIkeyFetchStatus === FETCH_STATUS_REQUEST ||
      signAPIKeyFetchStatus === FETCH_STATUS_REQUEST
    ) {
      output = (
        <ModalOverlay>
          <Spinner />
        </ModalOverlay>
      )
    }
    return output
  }

  handleCreateAPIKeyClicked = () => {
    this.prepareCreateAPIKeyModal()
    this.setShowAPIKeyModal()
  }

  setShowAPIKeyModal = () => {
    this.setState({
      showAPIKeyModal: true
    })
  }

  prepareCreateAPIKeyModal = () => {
    this.setState({
      selectedAPIKeyData: {
        token: '',
        project_id: '',
        codedeploy_name: '',
        codedeploy_tag: '',
        zerotier_id: '',
        status: STATUS_INITIAL
      }
    })
  }

  handleCloseDeleteAPIKeyModal = () => {
    this.setState({
      showDeleteAPIKeyModal: false,
      selectedAPIKeyData: undefined
    })
  }

  handleDeleteAPIKeyBtnClicked = () => {
    this.props.deleteAPIKey(this.state.selectedAPIKeyData)
    this.handleCloseDeleteAPIKeyModal()
  }

  getDeleteAPIKeyModal() {
    let output = null
    if (this.state.showDeleteAPIKeyModal) {
      output = (
        <ModalNotification
          title="Delete API Key"
          message="Are you sure to delete this API Key ?"
          onSecondaryButtonClick={this.handleCloseDeleteAPIKeyModal}
          onPrimaryButtonClick={this.handleDeleteAPIKeyBtnClicked}
          onOverLayClose={this.handleCloseDeleteAPIKeyModal}
          secondaryButtonText="Cancel"
          primaryButtonText="Delete"
          className="delete-apikey-modal"
        />
      )
    }
    return output
  }

  handleCloseAPIKeyModal = () => {
    this.setState({
      showAPIKeyModal: false,
      selectedAPIKeyData: undefined
    })
  }

  handleCreateNewAPIkey = () => {
    const data = {
      apikey: {
        project_id: this.state.selectedAPIKeyData.project_id,
        token: this.state.selectedAPIKeyData.token.trim(),
        codedeploy_name: this.state.selectedAPIKeyData.codedeploy_name.trim(),
        codedeploy_tag: this.state.selectedAPIKeyData.codedeploy_tag.trim(),
        zerotier_id: this.state.selectedAPIKeyData.zerotier_id.trim(),
        status: this.state.selectedAPIKeyData.status.trim()
      }
    }
    this.props.createNewAPIKey(data)
    this.handleCloseAPIKeyModal()
  }

  handleUpdateAPIkey = () => {
    const data = {
      apikey: {
        project_id: this.state.selectedAPIKeyData.project_id,
        token: this.state.selectedAPIKeyData.token.trim(),
        codedeploy_name: this.state.selectedAPIKeyData.codedeploy_name.trim(),
        codedeploy_tag: this.state.selectedAPIKeyData.codedeploy_tag.trim(),
        zerotier_id: this.state.selectedAPIKeyData.zerotier_id.trim(),
        status: this.state.selectedAPIKeyData.status.trim()
      }
    }
    this.props.updateAPIKey(data, this.state.selectedAPIKeyData.id)
    this.handleCloseAPIKeyModal()
  }

  handleSubmitAPIKeyBtnClicked = () => {
    this.state.selectedAPIKeyData.id ? this.handleUpdateAPIkey() : this.handleCreateNewAPIkey()
  }

  handleNewAPIKeyInputDataChanged = (value, property) => {
    this.setState({
      selectedAPIKeyData: Object.assign({}, this.state.selectedAPIKeyData, {
        [property]: value
      })
    })
  }

  shouldDisabledCreateNewAPIKeyBtn() {
    const isProjectIDValid = !!this.state.selectedAPIKeyData.project_id
    const isTokenValid = !!this.state.selectedAPIKeyData.token.trim()
    const isCodeDeployNameValid = this.state.selectedAPIKeyData.codedeploy_name && !!this.state.selectedAPIKeyData.codedeploy_name.trim()
    const isCodeDeployTagValid = this.state.selectedAPIKeyData.codedeploy_tag && !!this.state.selectedAPIKeyData.codedeploy_tag.trim()
    const isZeroTierIDValid = this.state.selectedAPIKeyData.zerotier_id && !!this.state.selectedAPIKeyData.zerotier_id.trim()
    const isDeployStatusValid = this.state.selectedAPIKeyData.status && !!this.state.selectedAPIKeyData.status.trim()
    return !(isProjectIDValid && isTokenValid && isCodeDeployNameValid && isCodeDeployTagValid && isZeroTierIDValid && isDeployStatusValid)
  }

  handleSelectedProjectChanged = (data) => {
    this.setState({
      selectedAPIKeyData: Object.assign({}, this.state.selectedAPIKeyData, {
        project_id: data.value,
        token: `${data.text.replace(/\s/g, '').toLowerCase()}`,
        codedeploy_name: `${data.text.replace(/\s/g, '').toLowerCase()}`,
        codedeploy_tag: `${data.text.replace(/\s/g, '').toLowerCase()}${moment().format('DDMMYYYY')}`
      })
    })
  }

  handleDeployStatusChanged = (data) => {
    this.setState({
      selectedAPIKeyData: Object.assign({}, this.state.selectedAPIKeyData, {
        status: data.value
      })
    })
  }

  getListProjectDropdownContainer() {
    const allProjectOptions = this.props.projectPage.listProject.map((data) => Object.assign({}, { text: data.name, value: data.project_id }))
    const allProjectOptionsNeverCreate = allProjectOptions.filter((data) => {
      const isCreated = this.props.apiKeyPage.listAPIKey.find((apiKeyData) => apiKeyData.project_id === data.value)
      return !isCreated
    })
    return (
      <div className="modal-apikey-row-container">
        <Dropdown
          className="project-dropdown"
          label="Project"
          labelPosition="top"
          options={this.state.selectedAPIKeyData.id ? allProjectOptions : allProjectOptionsNeverCreate}
          onChange={this.handleSelectedProjectChanged}
          value={this.state.selectedAPIKeyData.project_id}
          language={this.props.currentUser.language}
          buttonColor="#27a448"
          disabled={!!this.state.selectedAPIKeyData.id}
        />
      </div>
    )
  }

  getDeployStatusDropdown() {
    return (
      <div className="modal-apikey-row-container">
        <Dropdown
          className="project-dropdown"
          label="Status"
          labelPosition="top"
          options={this.state.listDeployStatus}
          onChange={this.handleDeployStatusChanged}
          value={this.state.selectedAPIKeyData.status}
          language={this.props.currentUser.language}
          buttonColor="#27a448"
        />
      </div>
    )
  }

  getAPIKeyModal() {
    let output = null
    if (this.state.showAPIKeyModal) {
      const title = this.state.selectedAPIKeyData.id ? 'Edit API Key' : 'Create New API Key'
      const btnText = this.state.selectedAPIKeyData.id ? 'Edit' : 'Create'
      output = (
        <ModalOverlay onClose={this.handleCloseAPIKeyModal}>
          <div className="modal-apikey-container">
            <div className="modal-apikey-header-container">{title}</div>
            {this.getListProjectDropdownContainer()}
            <div className="modal-apikey-row-container">
              <TextInput
                placeHolder="Deploy name"
                label="Code deploy name"
                value={this.state.selectedAPIKeyData.codedeploy_name}
                onChange={(e, newValue) => this.handleNewAPIKeyInputDataChanged(newValue, 'codedeploy_name')}
                mode="material-design"
              />
            </div>
            <div className="modal-apikey-row-container">
              <TextInput
                placeHolder="Deploy tag"
                label="Code deploy tag"
                value={this.state.selectedAPIKeyData.codedeploy_tag}
                onChange={(e, newValue) => this.handleNewAPIKeyInputDataChanged(newValue, 'codedeploy_tag')}
                mode="material-design"
              />
            </div>
            <div className="modal-apikey-row-container">
              <TextInput
                placeHolder="ID"
                label="Zero tier ID"
                value={this.state.selectedAPIKeyData.zerotier_id}
                onChange={(e, newValue) => this.handleNewAPIKeyInputDataChanged(newValue, 'zerotier_id')}
                mode="material-design"
              />
            </div>
            {this.getDeployStatusDropdown()}
            <div className="modal-apikey-btn-container">
              <Button
                className="modal-apikey-btn"
                onClick={this.handleSubmitAPIKeyBtnClicked}
                text={btnText}
                disabled={this.shouldDisabledCreateNewAPIKeyBtn()}
              />
            </div>
          </div>
        </ModalOverlay>
      )
    }
    return output
  }

  handleCloseCopyLinkModal = () => {
    this.setState({
      showCopyLinkModal: false,
      selectedAPIKeyData: undefined
    })
  }

  handleCopyLinkBtnClicked = () => {
    this.copyToClipBoard(this.state.selectedAPIKeyData.api_key)
    this.handleShowCopyAPIKeyTooltip()
  }

  handleShowCopyAPIKeyTooltip = () => {
    this.setState({
      showCopyAPIKeyTooltip: true
    })
  }

  handleCloseCopyAPIKeyTooltip = () => {
    this.setState({
      showCopyAPIKeyTooltip: false
    })
  }

  getCopyLinkTokenModal() {
    let output = null
    if (this.state.showCopyLinkModal) {
      output = (
        <ModalOverlay onClose={this.handleCloseCopyLinkModal}>
          <div className="modal-content-container" onClick={(e) => e.stopPropagation()}>
            <div>
              <div className="modal-content-row-container signed-apikey-input">
                <TextInput
                  mode="material-design"
                  type="text"
                  label="Signed API key"
                  placeHolder="Signed API key"
                  value={this.state.selectedAPIKeyData.api_key}
                  readOnly
                />
              </div>
            </div>
            <ClickAwayListener onClickAway={this.handleCloseCopyAPIKeyTooltip}>
              <div>
                <Tooltip
                  PopperProps={{
                    disablePortal: true
                  }}
                  onClose={this.handleCloseCopyAPIKeyTooltip}
                  open={this.state.showCopyAPIKeyTooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Copied"
                >
                  <div>
                    <Button className="full-width" id="copy-apikey-btn" onClick={this.handleCopyLinkBtnClicked} text="Copy API Key" />
                  </div>
                </Tooltip>
              </div>
            </ClickAwayListener>
          </div>
        </ModalOverlay>
      )
    }
    return output
  }

  getFailureModal() {
    let output = null
    if (this.state.failureModalData.show) {
      output = (
        <ModalNotification
          className={'default-failure-modal'}
          title={this.state.failureModalData.title}
          message={this.state.failureModalData.message}
          onOverLayClose={this.state.failureModalData.onOverLayClose}
        />
      )
    }
    return output
  }

  getFrontendVersion() {
    return (
      <div className="version-container">
        <div className="label">Front end:</div>
        <div className="value">v{process.env.APP_VERSION}</div>
      </div>
    )
  }

  handleCloseOptionMenu = () => {
    this.setState({
      anchorElOption: undefined
    })
  }

  handleSeeAPIKeyClicked = () => {
    this.setShowCopyLinkModal()
    this.handleCloseOptionMenu()
  }

  handleResignAPIKeyClicked = () => {
    this.props.signAPIKey(this.state.selectedAPIKeyData.id)
    this.handleCloseOptionMenu()
  }

  handleEditAPIKeyClicked = () => {
    this.setShowAPIKeyModal()
    this.handleCloseOptionMenu()
  }

  handleDeleteAPIKeyClicked = () => {
    this.showDeleteAPIKeyModal()
    this.handleCloseOptionMenu()
  }

  renderOptionsMenu = () => {
    const isAPIKeyAvailable = this.state.selectedAPIKeyData && !!this.state.selectedAPIKeyData.api_key
    const seeAPIkeyOption = (
      <MenuItem onClick={this.handleSeeAPIKeyClicked}>
        <StyledListItemIcon>
          <FaRegEye />
        </StyledListItemIcon>
        <StyledTypography variant="inherit" noWrap>
          See API Key
        </StyledTypography>
      </MenuItem>
    )
    return (
      <Menu
        id="api-keys-option"
        anchorEl={this.state.anchorElOption}
        keepMounted
        open={Boolean(this.state.anchorElOption)}
        onClose={this.handleCloseOptionMenu}
      >
        {isAPIKeyAvailable ? seeAPIkeyOption : null}
        <MenuItem onClick={this.handleResignAPIKeyClicked}>
          <StyledListItemIcon>
            <FaKey />
          </StyledListItemIcon>
          <StyledTypography variant="inherit" noWrap>
            Re-sign API Key
          </StyledTypography>
        </MenuItem>
        <MenuItem onClick={this.handleEditAPIKeyClicked}>
          <StyledListItemIcon>
            <FaEdit />
          </StyledListItemIcon>
          <StyledTypography variant="inherit" noWrap>
            Edit
          </StyledTypography>
        </MenuItem>
        <MenuItem onClick={this.handleDeleteAPIKeyClicked}>
          <StyledListItemIcon>
            <FaTrash />
          </StyledListItemIcon>
          <StyledTypography variant="inherit" noWrap>
            Delete
          </StyledTypography>
        </MenuItem>
      </Menu>
    )
  }

  render() {
    return (
      <APIKeyPageStyled>
        <div className="api-keys-page-container">
          <Paper>
            <div className="title-table">
              <div className="title">List API Keys</div>
              <div className="create-btn-container">
                <button onClick={this.handleCreateAPIKeyClicked}>+</button>
              </div>
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Project ID</TableCell>
                  <TableCell align="left">Project Name</TableCell>
                  <TableCell align="left">Code Deploy Name</TableCell>
                  <TableCell align="left">Code Deploy Tag</TableCell>
                  <TableCell align="left">Zero Tier ID</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Options</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{this.getTableBody()}</TableBody>
            </Table>
          </Paper>
        </div>
        {this.renderOptionsMenu()}
        {this.getAPIKeyModal()}
        {this.getDeleteAPIKeyModal()}
        {this.getCopyLinkTokenModal()}
        {this.getFrontendVersion()}
        {this.getFailureModal()}
        {this.getLoading()}
      </APIKeyPageStyled>
    )
  }
}

APIKeyPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  apiKeyPage: PropTypes.object.isRequired
}

/* istanbul ignore next */
const mapStateToProps = (state) => {
  return {
    apiKeyPage: state.apiKeyPage,
    currentUser: state.loginPage.loginAPI.userData,
    projectPage: state.projectPage
  }
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Object.assign({}, APIKeyPageActions, ProjectPageActions), dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(APIKeyPage)
