import styled from 'styled-components'

export default styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  .api-keys-page-container {
    max-width: 1190px;
    height: 100%;
    margin: 0 auto;
    padding: 30px 0px;
    .MuiPaper-root {
      margin-bottom: 30px;
      .title-table {
        display: flex;
        justify-content: space-between;
        padding: 0 16px;
        .title {
          font-family: 'Prompt-SemiBold', sans-serif;
          padding: 10px 0px;
          font-size: 24px;
        }
      }
      .create-btn-container {
        display: flex;
        align-items: center;
        padding: 10px 0px;
        button {
          font-size: 26px;
          padding: 0px;
          background-color: transparent; /* Green */
          border: none;
          text-decoration: none;
          color: rgba(0, 0, 0, 0.87);
          cursor: pointer;
          outline: none;
        }
      }
      .MuiTable-root {
        .MuiTableHead-root {
          .MuiTableCell-head {
            font-family: 'Prompt', sans-serif;
            font-size: 14px;
          }
        }
        .MuiTableCell-root {
          font-family: 'Prompt', sans-serif;
          font-size: 14px;
          max-width: 200px;
        }
      }
    }
    .deploy-status {
      padding: 4px 6px;
      border-radius: 5px;
      font-weight: bold;
      &.initial {
        background: ${(props) => props.theme.initialBackgroundTag};
        color: white;
      }
      &.work-in-progress {
        background: ${(props) => props.theme.wipBackgroundTag};
        color: white;
      }
      &.camera-assessment {
        background: ${(props) => props.theme.cameraAssessBackgroundTag};
        color: white;
      }
      &.deploy {
        background: ${(props) => props.theme.greenFont};
        color: white;
      }
    }
  }
  .version-container {
    position: fixed;
    bottom: 10px;
    left: 140px;
    display: flex;
    align-items: center;
    color: white;
    font-family: 'Prompt', sans-serif;
    font-size: 14px;
    .label {
      font-weight: bold;
      margin-right: 6px;
    }
    .value {
      font-style: italic;
    }
  }
`
